<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 60px">
				<div>
					<h3 class="card-title align-items-start flex-column">
						<span class="card-label font-weight-bolder"> Tickets </span>
					</h3>

					<span class="text-muted mt-3 font-weight-bold font-size-sm" v-if="loaded"
						>{{ tickets.length }} tickets</span
					>
				</div>
				<div class="card-toolbar">
					<div class="search-box">
						<b-form-input
							class="search-bar"
							size="sm"
							v-model="searchText"
							placeholder="Search"
							debounce="500"
						/>
						<b-icon-search class="search-icon" />
					</div>
				</div>
			</div>

			<div class="card-body pt-2 pb-0" style="max-height: 500px; overflow-y: none">
				<ITCSpinner :loaded="loaded">
					<template #default>
						<!--begin::Table-->
						<b-table
							v-if="tickets.length > 0"
							striped
							hover
							:items="filteredTickets"
							:fields="fields"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:current-page="currentPage"
							:per-page="perPage"
							@row-clicked="rowClickHandler"
						>
							<template #cell(ticketid)="data">
								<div class="d-flex align-items-center">
									<div class="symbol symbol-50 symbol-light mr-4">
										<span class="symbol-label">
											<inline-svg :src="icons(data.item['Request Mode'])" />
										</span>
									</div>
									<div>
										<router-link
											:to="{ name: 'ticket', params: { id: data.item.ticketid } }"
											class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
											>{{ data.item.ticketid }}
										</router-link>
										<span class="text-muted font-weight-bold d-block">{{
											data.item.Requester
										}}</span>
									</div>
								</div>
							</template>
							<template #cell(Status)="data">
								<span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
									data.item.StatusState
								}}</span>
								<span class="text-muted font-weight-bold">{{ data.item['Request Mode'] }}</span>
							</template>

							<template #cell(Created)="data">
								<span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
									data.item.created
								}}</span>
							</template>
							<template #cell(Site)="data">
								<span
									v-if="data.item.SiteId"
									class="text-dark-75 font-weight-bolder d-block font-size-lg"
								>
									<router-link :to="{ name: 'site', params: { id: data.item.SiteId } }"
										>{{ data.item.SiteName }}
									</router-link>
								</span>
								<span v-else class="text-dark-75 font-weight-bolder d-block font-size-lg">
									{{ data.item.SiteName }}
								</span>
								<span class="text-muted font-weight-bold">{{ data.item.Customer }}</span>
							</template>
						</b-table>
						<b-alert show v-if="tickets.length == 0">No open tickets</b-alert>
					</template>
				</ITCSpinner>
			</div>
			<div class="footer" v-if="filteredTickets.length > 0 && tickets.length > perPage">
				<b-pagination
					class="pagination"
					v-model="currentPage"
					:total-rows="filteredTickets.length"
					:per-page="perPage"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_READY_STATE } from '@/core/services/store/ready.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';
import router from '@/router';

export default {
	name: 'TicketList',
	mixins: [swMixin],
	data() {
		return {
			tickets: [],
			perPage: 6,
			fields: [
				{ key: 'ticketid', label: 'Ticket ID', sortable: true, sortDirection: 'desc' },
				'Status',
				{ key: 'Created' },
				'Site',
				{ key: 'Title', label: 'Description' },
			],
			sortBy: 'ticketid',
			sortDesc: true,

			searchText: '',
			currentPage: 1,
			loaded: false,
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		filteredTickets() {
			if (!this.searchText) return this.tickets;
			return this.tickets.filter((ticket) => {
				return (
					ticket.ticketid.includes(this.searchText) ||
					ticket.SiteName.toLowerCase().includes(this.searchText.toLowerCase()) ||
					ticket.Title.toLowerCase().includes(this.searchText.toLowerCase()) ||
					ticket.Requester.toLowerCase().includes(this.searchText.toLowerCase())
				);
			});
		},
	},
	methods: {
		processData(data) {
			if (data) {
				var keys = Object.keys(data);
				var tarr = [];
				if (data[keys[0]] == null) {
					return [];
				}
				for (var i = 0; i < keys.length; i++) {
					data[keys[i]].ticketid = keys[i];
					tarr.push(data[keys[i]]);
				}
				return tarr;
			}
		},
		icons(v) {
			switch (v) {
				case 'E-Mail':
					return '/media/svg/icons/Communication/Mail.svg';
				case 'Phone Call':
					return '/media/svg/icons/Communication/Call.svg';
				case 'Portal':
					return '/media/svg/icons/Home/Earth.svg';
				default:
					return '/media/svg/icons/Shopping/ticket.svg';
			}
		},
		rowClickHandler(record, index) {
			router.push({ name: 'ticket', params: { id: record.ticketid } });
		},
	},
	created() {
		this.loaded = false;
		this.$http.get('tickets/all').then((resp) => {
			this.tickets = this.processData(resp.data.data);
			//this.$store.commit(SET_READY_STATE, { component: "SiteList", ready: true });
			this.loaded = true;
		});
		this.setSWListener('tickets/all', (data) => {
			this.tickets = this.processData(data.data);
		});
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
</style>
