<template>
	<transition name="fade" mode="out-in">
		<div v-if="!loaded" class="itc-loader" key="loader">
			<b-spinner variant="primary" label="Loading..." class="large-thick-spinner" style="width: 10rem; height: 10rem; border-width: 1rem;"></b-spinner>
			<!-- <div class="svgWrapper"> -->
				<!-- <svg
					version="1.1"
					id="itc-sat"
					xmlns="http://www.w3.org/2000/svg"
					x="0"
					y="0"
					width="180"
					height="218"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M112.4 1.7c6.8 6.5 12.9 13.7 18.3 21.5 11.9 17.2 19.6 36.2 23.4 56.7 1.2 6.5 1.9 13 2.3 19.6.4 7.6.3 15.1-.3 22.6-2.2 24.7-9.8 47.5-23.3 68.3-5.5 8.4-11.9 16.2-19 23.3-.5.5-1.1 1-1.6 1.5-.2-.4.1-.6.3-.8 14-19.9 23.8-41.7 29.2-65.4 2-8.6 3.3-17.2 4-26 .5-6.2.8-12.4.6-18.7-.4-16.8-3-33.2-7.9-49.3-5.9-18.7-14.4-36-25.6-52.1-.2-.3-.4-.7-.7-1-.1-.2.1-.3.3-.2z"
					/>
					<path
						class="st0"
						d="M108.6 46.1c6.1 5.7 11.3 12.1 15.7 19.1 5.5 8.7 9.6 18 11.7 28.2 2.9 13.9 1.8 27.5-3.1 40.8-4.3 11.8-10.9 22.1-19.2 31.4-1.5 1.6-3 3.2-4.6 4.7-.2.1-.3 0-.3-.3 4.1-6.2 7.3-12.8 9.8-19.8 2.3-6.7 3.9-13.7 4.9-20.7 1.1-8 1.5-16.1 1.4-24.1-.3-15.3-2.5-30.3-8.5-44.5-2.2-5.1-4.9-10-8-14.6 0-.3.1-.3.2-.2zM69.1 104.3c0-8.1 6.4-14.5 14.5-14.5s14.5 6.5 14.5 14.6c0 8-6.6 14.5-14.6 14.5-7.9-.1-14.4-6.6-14.4-14.6z"
					/>
					<animateTransform
						attributeType="xml"
						attributeName="transform"
						type="rotate"
						from="360 -6.4 -4.6"
						to="0 -6.4 -4.6"
						dur="2s"
						repeatCount="indefinite"
					/>
				</svg> -->

			<!-- </div> -->
		</div>
		<div v-else key="loadedView" id="view">
			<slot></slot>
		</div>
	</transition>
</template>

<script>
export default {
	name: "ITCSpinner",
	props: {
		loaded: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style scoped>
.st0 {
	fill: #fec938;
}
.svgWrapper {
	display: inline-block;
	width: 190px;
	height: 230px;
	transform-box: fill-box;
	transform-origin: center;
	/* animation: spinner 2s ease-in-out infinite; */
}
#itc-sat {
	/* border: 2px solid red;  */
	margin: 9.95px 11.85px;
}
.itc-loader {
	text-align: center;
	margin: 45px;
}
.fade-enter-active {
	transition: opacity 300ms ease-in-out;
}
.fade-enter {
	opacity: 0;
}
.fade-leave-active {
	animation: bounce-out 0.5s;
}

#view.fade-leave-active {
	animation: fade-out 0.3s;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

@keyframes bounce-out {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.25);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>
